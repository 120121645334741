import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarTiposColaboradores, filtarTiposColaboradores } from '../../../actions/tipos_colaboradores';

import FiltrosTipo from './FiltrosTipo';
import EliminarTipo from './EliminarTipo';

const Tipos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Tipos de colaborador',
            path: '/colaboradores/tipos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.tipos_colaboradores);

    const [tipos, setTipos] = useState([]);

    const [tipoEliminar, setTipoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Colaboradores asignados',
            selector: row => row.colaboradores,
        },
        {
            name: 'Permisos asignados',
            selector: row => row.permisos_asignados + '/' + row.permisos_totales,
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarTipo(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/colaboradores/tipos/editar/${row.id}`}><i className="fas fa-edit text-warning"></i></Link>
                    </div>
                )
            }
        }
    ];
    
    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarTiposColaboradores(true));
    }

    /* Eliminar tipo */
    const handleEliminarTipo = (tipo) => {
        setTipoEliminar(tipo);
    }

    useEffect(() => {
        dispatch(buscarTiposColaboradores({
            nombre: null
        }));
    }, []);

    useEffect(() => {
        setTipos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosTipo/>
            <EliminarTipo tipo={tipoEliminar} reestablecerTipo={handleEliminarTipo} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Tipos de colaborador</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>

                        <Link to="/colaboradores/tipos/nuevo">
                            <button className="btn btn-primary">Agregar tipo</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={tipos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Tipos;