import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { setAlert } from '../../../actions/alert';
import { crearMetodoConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevaMetodo = ({ metodo, reestablecerMetodo }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [metodoData, setMetodoData] = useState({
        nombre: null,
        tipo: null,
        descripcion: null
    });

    const [tipoData, setTipoData] = useState(null);

    const tiposFilter = [
        { value: 1, label: 'Cobrar' }, 
        { value: 2, label: 'Pagar' }, 
        { value: 3, label: 'Ambos' }
    ];
    
    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "20px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Crear metodo */
    const handleInputChange = ({ target }) => {
        setMetodoData({
            ...metodoData,
            [target.name]: target.value
        });
    }

    const handleSelectTipo = (option) => {
        setTipoData(option);

        setMetodoData({
            ...metodoData,
            tipo: option ? option.value : null
        });
    }
    
    const handleProcesar = async () => {
        if(metodoData.nombre){
            await setStatusProcesar(true);
            await dispatch(crearMetodoConfiguracion(metodoData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('Completa todos los campos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setMetodoData({
                nombre: null, 
                tipo: null,
                descripcion: null
            });
            setTipoData(null);
            setNuevoModal(false);
            reestablecerMetodo(null);
        }
    }

    useEffect(() => {
        if(metodo){
            setNuevoModal(true);

            setMetodoData({
                ...metodoData
            });
        }        
    }, [metodo]);

    return (
        <Modal show={nuevoModal} size="md" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar metodo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={metodoData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating h-100">
                                <Select
                                    name="tipo"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Tipo"
                                    options={tiposFilter}
                                    value={tipoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectTipo(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Descripción" id="descripcion" name="descripcion" value={metodoData.descripcion} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="descripcion">Descripción</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevaMetodo;