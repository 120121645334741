import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarColaboradores, filtarColaboradores } from '../../../actions/colaboradores';

import FiltrosColaborador from './FiltrosColaborador';
import EliminarColaborador from './EliminarColaborador';

const Colaboradores = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Colaboradores',
            path: '/colaboradores'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.colaboradores);

    const [colaboradores, setColaboradores] = useState([]);

    const [colaboradorEliminar, setColaboradorEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Apellidos',
            selector: row => row.apellido,
        },
        {
            name: 'Tipo',
            selector: row => row.tipo_usuario.nombre || 'Sin definir',
        },
        {
            name: 'Correo',
            selector: row => row.email,
        },
        {
            name: 'Teléfono',
            selector: row => row.telefono,
        },
        {
            name: 'Status',
            selector: row => {
                switch(row.status){
                    case 10:
                        return(
                            <h5><Badge pill bg={row.contrataciones == 0 ? 'warning' : 'success'}>{row.contrataciones == 0 ? 'Activo y sin contrato' : 'Activo'}</Badge></h5>
                        )
                    case 5:
                        return(
                            <h5><Badge pill bg="danger">Inactivo</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarColaborador(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/colaboradores/colaboradores/editar/${row.id}`}><i className="fas fa-edit text-warning"></i></Link>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/colaboradores/colaboradores/perfil/${row.id}`}><i className="fas fa-circle-info text-info"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarColaboradores(true));
    }

    /* Eliminar colaborador */
    const handleEliminarColaborador = (colaborador) => {
        setColaboradorEliminar(colaborador);
    }

    useEffect(() => {
        dispatch(buscarColaboradores({
            nombre: null, 
            apellido: null, 
            tipo: null, 
            email: null, 
            telefono: null, 
            status: [5, 10]
        }));
    }, []);

    useEffect(() => {
        setColaboradores(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosColaborador/>
            <EliminarColaborador colaborador={colaboradorEliminar} reestablecerColaborador={handleEliminarColaborador} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Colaboradores</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>

                        <Link to="/colaboradores/colaboradores/nuevo">
                            <button className="btn btn-primary">Agregar colaborador</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={colaboradores}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Colaboradores;