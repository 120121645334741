import { COLABORADORES } from '../actions/types';

const initialState = {
    ventana_filtros: false,
    listado: [],
    detalles: null, 
    detalles_archivo: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case COLABORADORES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case COLABORADORES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case COLABORADORES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case COLABORADORES.CONTRATAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_personal: {
                        ...state.detalles.informacion_personal,
                        status: payload.status
                    }, 
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        contrataciones: [
                            ...payload.contrataciones
                        ]
                    }
                }
            };

        case COLABORADORES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( colaborador => colaborador.id !== payload.id )
            };

        case COLABORADORES.CONTRATACIONES.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_personal: {
                        ...state.detalles.informacion_personal,
                        status: payload.status
                    }, 
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        contrataciones: [
                            ...payload.contrataciones
                        ]
                    }
                }
            };

        case COLABORADORES.VACACIONES.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        vacaciones: [
                            ...payload.vacaciones
                        ]
                    }
                }
            };

        case COLABORADORES.INCIDENCIAS.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        incidencias: [
                            ...payload.incidencias
                        ]
                    }
                }
            };

        case COLABORADORES.REMOTOS.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        remotos: [
                            ...payload.remotos
                        ]
                    }
                }
            };
        
        case COLABORADORES.REPORTES.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        reportes: [
                            ...payload.reportes
                        ]
                    }
                }
            };

        case COLABORADORES.ARCHIVOS.LISTAR:
            return {
                detalles: {
                    ...state.detalles,
                    informacion_profesional: {
                        ...state.detalles.informacion_profesional,
                        archivos: [
                            ...payload.archivos
                        ]
                    }
                }
            };

        case COLABORADORES.ARCHIVOS.DETALLES:
            return {
                ...state,
                detalles_archivo: payload
            };
        
        default:
            return state;
    }
}